<template>
    <b-container fluid>
        <div class="mb-2 row">
          <div class="col-12">
            <h4>{{ distributor.name}}</h4>
          </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline card-outline-tabs">
                    <div class="p-0 card-header border-bottom-0">
                        <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                            <li class="nav-item">
                                <a href="#custom-tabs-four-general" class="nav-link active" id="custom-tabs-four-general-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-general" aria-selected="true">Allgemein</a>
                            </li>
                            <li class="nav-item">
                                <a href="#custom-tabs-four-articles" class="nav-link" id="custom-tabs-four-articles-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-articles" aria-selected="true">Artikel</a>
                            </li>
                            <li class="nav-item" v-if="$auth.check('distributors.documents')">
                                <a href="#custom-tabs-four-documents" class="nav-link" id="custom-tabs-four-documents-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-documents" aria-selected="false">Dokumente</a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="tab-content" id="custom-tabs-four-tabContent">
                            <div class="tab-pane fade active show" id="custom-tabs-four-general" role="tabpanel" aria-labelledby="custom-tabs-four-general">
                                <form ref="form" @submit.stop.prevent="handleSubmit">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="row" >
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="name" class="control-label">Name*</label>
                                                        <input v-model="form.name" name="name" id="name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('name')}">
                                                        <has-error :form="form" field="name"></has-error>
                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="owner" class="control-label">Inhaber</label>
                                                        <input v-model="form.owner" name="owner" id="owner" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('owner')}">
                                                        <has-error :form="form" field="owner"></has-error>
                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="ustid" class="control-label">USt-Id.</label>
                                                        <input v-model="form.ustid" name="ustid" id="ustid" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('ustid')}">
                                                        <has-error :form="form" field="ustid"></has-error>
                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="number" class="control-label" @dblclick="editNumber()">Lieferantenummer*</label>
                                                        <input v-model="form.distributornumber" name="number" id="number" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('distributornumber')}" :disabled="changeDistriNumber == false">
                                                        <has-error :form="form" field="distributornumber"></has-error>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="send_email">
                                            <label class="custom-control-label" for="customSwitch1">Bestellung per Mail</label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="email" class="control-label">E-Mail*</label>
                                        <input v-model="form.email" type="email" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('email')}" :disabled="form.send_email == 0">
                                        <has-error :form="form" field="email"></has-error>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="send_phone">
                                            <label class="custom-control-label" for="customSwitch2">Bestellung per Telefon</label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="email" class="control-label">Telefon*</label>
                                        <input v-model="form.phone" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('phone')}" :disabled="form.send_phone == 0">
                                        <has-error :form="form" field="phone"></has-error>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch3" v-model="send_web">
                                            <label class="custom-control-label" for="customSwitch3">Bestellung per Webshop</label>
                                        </div>
                                    </div>
                                    <div class="form-group" >
                                        <label for="email" class="control-label">URL*</label>
                                        <input v-model="form.web" type="email" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('web')}" :disabled="form.send_web == 0">
                                        <has-error :form="form" field="web"></has-error>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch4" v-model="use_credit">
                                            <label class="custom-control-label" for="customSwitch4">Teilnahme am Gutschriftverfahren</label>
                                        </div>
                                    </div>
                                    <div class="form-group" >
                                        <label for="email" class="control-label">E-Mail*</label>
                                        <input v-model="form.credit_email" type="email" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('credit_email')}" :disabled="form.useCredit == 0">
                                        <has-error :form="form" field="credit_email"></has-error>
                                    </div>
                                </div>
                            </div>
                                            

                                            

                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label for="street" class="control-label">Straße*</label>
                                                            <input v-model="form.street" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('street')}" >
                                                            <has-error :form="form" field="street"></has-error>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label for="house_number" class="control-label">Nr.*</label>
                                                            <input v-model="form.house_number" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('house_number')}">
                                                            <has-error :form="form" field="house_number"></has-error>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <label for="zipcode" class="control-label">PLZ*</label>
                                                        <input v-model="form.zipcode" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('zipcode')}">
                                                        <has-error :form="form" field="zipcode"></has-error>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <label for="city" class="control-label">Ort*</label>
                                                        <input v-model="form.city" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('city')}">
                                                        <has-error :form="form" field="city"></has-error>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" id="customSwitch8" v-model="use_warenausgabe">
                                                    <label class="custom-control-label" for="customSwitch8">Abweichende Warenausgabe</label>
                                                </div>
                                            </div>

                                            <div class="form-group" v-if="use_warenausgabe == true">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label for="street_pickup" class="control-label">Straße</label>
                                                            <input v-model="form.street_pickup" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('street_pickup')}" >
                                                            <has-error :form="form" field="street_pickup"></has-error>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label for="house_number_pickup" class="control-label">Nr.</label>
                                                            <input v-model="form.house_number_pickup" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('house_number_pickup')}">
                                                            <has-error :form="form" field="house_number_pickup"></has-error>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <label for="zipcode_pickup" class="control-label">PLZ</label>
                                                        <input v-model="form.zipcode_pickup" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('zipcode_pickup')}">
                                                        <has-error :form="form" field="zipcode_pickup"></has-error>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <label for="city_pickup" class="control-label">Ort</label>
                                                        <input v-model="form.city_pickup" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('city_pickup')}">
                                                        <has-error :form="form" field="city_pickup"></has-error>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="row mb-2" style="color: #499360;">
                                                <div class="col-md-4">
                                                    <label for="skonto" class="control-label">Skonto</label>
                                                    <input v-model="form.skonto" type="number" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('skonto')}">
                                                    <has-error :form="form" field="skonto"></has-error>
                                                </div>

                                                <div class="col-md-4">
                                                    <label for="min_order_amount" class="control-label">Mindestbestellwert</label>
                                                    <input v-model="form.min_order_amount" type="number" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('min_order_amount')}">
                                                    <has-error :form="form" field="min_order_amount"></has-error>
                                                </div>

                                                <div class="col-md-4">
                                                    <label for="delivery_days" class="control-label">Liefertage</label>
                                                    <!-- <select v-model="form.delivery_days" multiple class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('delivery_days')}">
                                                        <option value="1">Montag</option>
                                                        <option value="2">Dienstag</option>
                                                        <option value="3">Mittwoch</option>
                                                        <option value="4">Donnerstag</option>
                                                        <option value="5">Freitag</option>
                                                    </select> -->
                                                    <Select2 v-model="form.delivery_days" :options="days" :settings="{ multiple: true }" :class="{'is-invalid': form.errors.has('delivery_days')}" />
                                                    <has-error :form="form" field="delivery_days"></has-error>
                                                </div>
                                            </div>

                                            <div class="row" style="color: #499360;">
                                                <div class="col-md-4">
                                                    <label for="iban" class="control-label">IBAN</label>
                                                    <input v-model="form.iban" type="string" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('iban')}">
                                                    <has-error :form="form" field="iban"></has-error>
                                                </div>

                                                <div class="col-md-4">
                                                    <label for="bic" class="control-label">BIC</label>
                                                    <input v-model="form.bic" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('bic')}">
                                                    <has-error :form="form" field="bic"></has-error>
                                                </div>

                                                <div class="col-md-4">
                                                    <label for="bank" class="control-label">Bank</label>
                                                    <input v-model="form.bank" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('bank')}">
                                                    <has-error :form="form" field="bank"></has-error>
                                                </div>
                                            </div>

                                            <div class="row" style="color: #499360;">
                                                <div class="col-md-4">
                                                    <label for="useGlobalTax" class="control-label">Pauschalisierte Besteuerung?</label>
                                                    <!-- <input v-model="form.useGlobalTax" type="string" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('useGlobalTax')}"> -->
                                                    <select v-model="form.useGlobalTax" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('useGlobalTax')}">
                                                        <option value="0">Nein</option>
                                                        <option value="1">Ja</option>
                                                    </select> 
                                                    <has-error :form="form" field="useGlobalTax"></has-error>
                                                </div>

                                                <div class="col-md-4">
                                                    <label for="singleCompany" class="control-label">Einzelunternehmer?</label>
                                                    <!-- <input v-model="form.useGlobalTax" type="string" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('useGlobalTax')}"> -->
                                                    <select v-model="form.singleCompany" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('singleCompany')}">
                                                        <option value="0">Nein</option>
                                                        <option value="1">Ja</option>
                                                    </select> 
                                                    <has-error :form="form" field="singleCompany"></has-error>
                                                </div>
                                            </div>

                                            <div class="row mt-4">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="name" class="control-label">Allgemeine Infos (Intern)</label>
                                                        <ckeditor :editor="editor" :config="editorConfig" v-model="form.description"></ckeditor>
                                                        <has-error :form="form" field="description"></has-error>
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="name" class="control-label">Bestell Infos (Intern)</label>
                                                        <ckeditor :editor="editor" :config="editorConfig" v-model="form.orderinfo"></ckeditor>
                                                        <has-error :form="form" field="orderinfo"></has-error>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="my-4">
                                                <h2>Kontakte </h2>
                                                <b-row class="mb-3" >
                                                    <b-col md="3" v-if="contacts.length > 0">
                                                        <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Suche..."></b-form-input>
                                                    </b-col>
                                                    <b-col md="3" v-if="contacts.length == 0">
                                                        <h4>Dieser Lieferant hat keine Kontakte</h4>
                                                    </b-col>
                                                    <b-col md="3" offset-md="6" class="text-right">
                                                        <b-button size="sm" @click="createModal" class="mr-1" variant="primary"><i class="fas fa-plus-circle" v-if="$auth.check('distributors.edit')"></i> Neuer Kontakt</b-button>
                                                    </b-col>
                                                </b-row>
                                                <b-row v-if="contacts.length >= 1">
                                                    <b-col>
                                                        <b-table striped hover outlined :fields="contactFields" :items="contacts" :filter="filter" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                                                            <template #cell(actions)="row">
                                                                <b-button size="sm" @click="editModal(row.item)" class="mr-1" variant="warning" v-if="$auth.check('distributors.edit')"><i class="fas fa-edit" ></i></b-button>
                                                                <b-button size="sm" @click="deleteContact(row.item.id)" variant="danger" v-if="$auth.check('distributors.edit')"><i class="fas fa-trash" ></i></b-button>
                                                            </template>
                                                        </b-table>
                                                        <b-pagination v-model="currentPage" :total-rows="contactRows" :per-page="perPage" aria-controls="my-table"></b-pagination>
                                                    </b-col>
                                                </b-row>                
                                            </div>

                                            

                                            <div class="form-group" v-if="$auth.check('distributors.edit')">
                                                <b-button size="sm" variant="success" @click="editDistributor">Speichern</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="tab-pane fade" id="custom-tabs-four-articles" role="tabpanel" aria-labelledby="custom-tabs-four-articles">
                                <div v-if="articles.data.length >= 1">
                                <div class="row">
                                    <div class="col-md-12">
                                    <div class="input-group input-group-sm">
                                        <input type="text" class="form-control" placeholder="Suchen" @keyup.enter="getAllArticles" v-model="search" />
                                        <button type="submit" class="btn btn-default" @click.prevent="getAllArticles">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                    <table class="table table-striped">
                                        <thead>
                                                <tr>
                                                    <th>
                                                        <a href="#" @click.prevent="changeSort('ordernumber')">Artikelnummer</a>
                                                        <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                                        <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                                                    </th>
                                                    <th>
                                                        <a href="#" @click.prevent="changeSort('han')">HAN</a>
                                                        <span v-if="this.params.sort_field == 'han' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                                        <span v-if="this.params.sort_field == 'han' && this.params.sort_direction == 'desc'" >&darr;</span>
                                                    </th>
                                                    <th>Artikelname</th>
                                                    <th>EK (Netto)</th>
                                                    <th>EK (Brutto)</th>
                                                    <th>VK (Brutto)</th>
                                                    <th>Steuersatz</th>
                                                    <th>Flags</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(article,index) in articles.data" :key="article.id" :class="article.active == 0 || article.article.active == 0 ? 'table-danger' : ''">
                                                <td>{{ article.ordernumber }}</td>
                                                <td>{{ article.han }}</td>
                                                <td>{{ generateName(index) }}</td>
                                                <td>{{ article.purchase_price | toCurrency}}</td>
                                                <td>{{ article.purchase_price_brutto | toCurrency}}</td>
                                                <td>{{ article.price | toCurrency}}</td>
                                                <td>{{ article.tax }}</td>
                                                <td>
                                                    <span class="ml-2 badge bg-primary" v-if="article.laststock == 1">AdS</span>
                                                    <span class="ml-2 badge bg-danger" v-if="article.not_available == 1">z.Z.n.l.</span>
                                                    <span class="ml-2 badge bg-success" v-if="article.mhd != null">MHD</span>
                                                    <span class="ml-2 badge bg-warning" v-if="article.seasonal == 1">Saison </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="clearfix align-middle card-footer">
                                        <pagination class="float-left" :data="articles" @pagination-change-page="getAllArticles" :limit="3"></pagination>
                                        
                                        <select class="float-right form-control-sm" v-model="params.per_page">
                                            <option value="25">25 Artikel</option>
                                            <option value="50">50 Artikel</option>
                                            <option value="75">75 Artikel</option>
                                            <option value="100">100 Artikel</option>
                                            <option value="125">125 Artikel</option>
                                            <option value="150">150 Artikel</option>
                                        </select>
                                        <p v-if="notEmptyObject(articles)" class="float-right mr-2">Anzeige Eintrag {{ articles.meta.from }} - {{ articles.meta.to }} von {{ articles.meta.total }}</p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <b-row v-if="articles.data.length == 0">
                                    <b-col>
                                        <h4>Dieser Lieferant hat keine Artikel</h4>
                                    </b-col>
                                </b-row>
                            </div>

                            <div class="tab-pane fade" v-if="$auth.check('distributors.documents')" id="custom-tabs-four-documents" role="tabpanel" aria-labelledby="custom-tabs-four-documents">
                                <Documents :distributor="distributor" v-if="notEmptyObject('distributor')"/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="contactModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, contactForm.id)">
                <form ref="contactForm" @submit.stop.prevent="handleSubmit">
                    <div class="row">
                        <div class="col-md-12">

                            <div class="form-group">
                                <label for="name" class="control-label">Name*</label>
                                <input v-model="contactForm.name" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('name')}">
                                <has-error :form="contactForm" field="name"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="department" class="control-label">Abteilung*</label>
                                <input v-model="contactForm.department" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('department')}">
                                <has-error :form="contactForm" field="department"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="email" class="control-label">E-Mail</label>
                                <input v-model="contactForm.email" type="email" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('email')}">
                                <has-error :form="contactForm" field="email"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="phone" class="control-label">Telefon</label>
                                <input v-model="contactForm.phone" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('phone')}">
                                <has-error :form="contactForm" field="phone"></has-error>
                            </div>

                            
                        </div>
                    </div>
                </form>
            </b-modal>
    </b-container>
</template>

<script>
import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';
import Documents from './components/Documents.vue';


export default {
    name: "Distributor-Details",
    title: "Lieferantendetails",

    components:{
        Documents
    },

    data() {
        return {
            days:[
                {
                    id: 1,
                    text: "Montag"
                },
                {
                    id: 2,
                    text: "Dienstag"
                },
                {
                    id: 3,
                    text: "Mittwoch"
                },
                {
                    id: 4,
                    text: "Donnerstag"
                },
                {
                    id: 5,
                    text: "Freitag"
                },
            ],
            orderForm: new window.Form({
                id: "",
                orderinfo: "",
            }),
            send_email: 0,
            send_phone: 0,
            send_web: 0,
            use_credit: 0,
            use_warenausgabe: 0,
            form: new window.UploadForm({
                id: "",
                name: "",
                description: "",
                street: "",
                house_number: "",
                zipcode: "",
                city: "",
                street_pickup: "",
                house_number_pickup: "",
                zipcode_pickup: "",
                city_pickup: "",
                number: "",
                orderinfo: "",
                min_order_amount: "",
                skonto: "",
                send_email: "",
                send_phone: "",
                send_web: "",
                email: "",
                phone: "",
                web:"",
                useCredit: "",
                use_warenausgabe: "",
                credit_email: "",
                delivery_days: [],
                _method: "PUT",
                owner: "",
                ustid: "",
                bank: "",
                iban: "",
                bic: "",
                distributornumber: '',
                useGlobalTax: 0,
                singleCompany: 0,
            }),
            changeDistriNumber: false,
            contactForm: new window.Form({
                id: "",
                name: "",
                department: '',
                phone: '',
                email: '',
            }),
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            modalTitle: "Neuen Kontakt erstellen",
            articles: {
                data:[],
            },
            contacts: [],
            distributor: {},
            editMode: false,
            editModeContact: false,
            params: {
                sort_field: 'ordernumber',
                sort_direction: 'desc',
                per_page: 25,
                distributor_id: this.$route.params.id,
            },
            search: '',
            contactFields: [
                {
                    key: 'name',
                    lable: 'Name',
                    sortable: true
                },
                {
                    key: 'department',
                    label: 'Abteilung',
                    sortable: true
                },
                {
                    key: 'email',
                    label: 'E-Mail',
                    sortable: true
                },
                {
                    key: 'phone',
                    label: 'Telefon',
                    sortable: true
                },
                {
                    key: 'actions',
                    label: 'Aktionen',
                    sortable: false,
                }
            ]
        }
    }, 

    watch:{
        params: {
            handler () {
                this.getAllArticles();
            },
            deep: true
        },
        
        use_warenausgabe: function(){
            //this.form.email = "";
            if(this.use_warenausgabe == true)
            {
                this.form.use_warenausgabe = 1;
            }
            else
            {
                this.form.use_warenausgabe = 0;
            }
        },

        send_email: function(){
            //this.form.email = "";
            if(this.send_email == true)
            {
                this.form.send_email = 1;
            }
            else
            {
                this.form.send_email = 0;
            }
        },

        send_phone: function(){
            //this.form.phone = "";
            if(this.send_phone == true)
            {
                this.form.send_phone = 1;
            }
            else
            {
                this.form.send_phone = 0;
            }
        },

        send_web: function(){
            //this.form.web = "";
            if(this.send_web == true)
            {
                this.form.send_web = 1;
            }
            else
            {
                this.form.send_web = 0;
            }
        },

        use_credit: function(){
            if(this.use_credit == true)
            {
                this.form.useCredit = 1;
            }
            else
            {
                this.form.useCredit = 0;
            }
        }
    },

    methods:{

        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        generateName(index)
        {
            var article = this.articles.data[index];
            var name = article.article.name;

            article.values.forEach(function (value) {
                name = name + ' - ' + value.name;
            });

            return name;
            
        },

        editNumber(){
            if(this.changeDistriNumber == true){
                this.changeDistriNumber = false;
            }
            else{
                this.changeDistriNumber = true;
            }
        },
        editOrderinfo(){
            this.$Progress.start();
            this.orderForm
                .post("/distributors/saveOrderinfo/" + this.distributor.id )
                .then(() => {
                    this.loadDistributor();
                    this.$swal({
                        icon: "success",
                        title: "Bestellinformationen wurden editiert",
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        handleOk(bvModalEvt, id) {
            bvModalEvt.preventDefault()
            this.handleSubmit(id)
        },

        handleSubmit(id) {
            if(this.editModeContact == true)
            {
                //Edit User
                this.editContact(id);
            }
            else
            {
                //Create User
                this.createContact();
            }
        },

        createModal() {
            this.contactForm.clear();
            this.contactForm.reset();
            this.editModeContact = false;
            this.$bvModal.show("contactModal");
        },

        editModal(Contact) {
            this.editModeContact = true;
            this.modalTitle = "Kontakt bearbeiten";
            this.contactForm.reset();
            this.contactForm.fill(Contact);
            this.$bvModal.show("contactModal");

        },

        isActive(value){
            if(value == 1)
            {
                return '<i class="fas fa-check"></i>';
            }
            else
            {
                return '<i class="fas fa-times"></i>';
            }
        },

        editModeOn() {
            this.editMode = true;
        },

        editModeOff() {
            this.form.reset();
            this.form.fill(this.distributor);
            this.form._method ="PUT";
            this.editMode = false;
        },

        createContact() {
            this.$Progress.start();
            this.contactForm
                .post("/distributors/" + this.distributor.id + '/contacts')
                .then(() => {
                    this.loadDistributor();
                    this.$bvModal.hide("contactModal");
                    this.$swal({
                        icon: "success",
                        title: "Kontakt wurde hinzugefügt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },

        editContact(id) {
            this.$Progress.start();
            this.contactForm
                .put("/distributors/" + this.distributor.id + '/contacts/' + id)
                .then(() => {
                    this.loadDistributor();
                    this.$bvModal.hide("contactModal");
                    this.$swal({
                        icon: "success",
                        title: "Der Kontakt wurde editiert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },

        deleteContact(id){
            this.$swal({
                title: "Möchtest du den Kontakt wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.form
                        .delete("/distributors/" + this.distributor.id + '/contacts/' + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Kontakt wurde gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.loadDistributor();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },

        editDistributor() {
            this.$Progress.start();
            this.form
                .post("/distributors/"+this.distributor.id)
                .then(() => {
                    this.loadDistributor();
                    this.editModeOff();
                    this.$swal({
                        icon: "success",
                        title: "Lieferant wurde editiert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.changeDistriNumber = false;
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },

        getAllArticles(page = 1) {
            this.axios
                .get("/details", {
                    params: {
                        page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.articles = response.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadDistributor() {
            this.$Progress.start();
            this.axios
                .get("/distributors/" + this.$route.params.id)
                .then((response) => {
                    this.distributor = response.data.data;
                    // this.articles = this.distributor.articles;
                    this.contacts = this.distributor.contacts;
                    if(this.distributor.description == null)
                    {
                        this.distributor.description = "";
                    }
                    this.send_email = this.distributor.send_email;
                    this.send_phone = this.distributor.send_phone;
                    this.send_web = this.distributor.send_web;
                    this.use_credit = this.distributor.useCredit;
                    this.use_warenausgabe = this.distributor.use_warenausgabe;
                    this.form.fill(this.distributor);
                    this.orderForm.fill(this.distributor);
                    this.form._method = "PUT";
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    created() {
        this.loadDistributor();
        this.getAllArticles();
    },

    computed: {
        contactRows() {
            return this.contacts.length;
        }
    },
}
</script>

<style>

</style>